"use client";

import dynamic from "next/dynamic";
import React, { useState } from "react";

const Header = dynamic(
  () => import("./_components/header/index").then((mod) => mod.Header),
  {
    ssr: true,
    loading: () => <div className="h-24 bg-gray-200" />,
  }
);

const Section1 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section1),
  {
    ssr: true,
    loading: () => <div className="h-[500px] bg-gray-200" />,
  }
);

// Componentes de sessão comentados para carregamento opcional
const Section2 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section2),
  { ssr: false }
);
const Section3 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section3),
  { ssr: false }
);
const Section4 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section4),
  { ssr: false }
);
const Section5 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section5),
  { ssr: false }
);
const Section6 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section6),
  { ssr: false }
);
const Section7 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section7),
  { ssr: false }
);
const Section8 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section8),
  { ssr: false }
);
const Section9 = dynamic(
  () => import("./_components/sectionsHomePage").then((mod) => mod.Section9),
  { ssr: false }
);

const Modal = dynamic(() => import("./_components/popupContato"), {
  ssr: false,
});

const Footer = dynamic(
  () => import("./_components/footer/index").then((mod) => mod.Footer),
  {
    ssr: false,
    loading: () => <div className="h-24 bg-gray-200" />,
  }
);

export const HomePage = () => {
  const [selectedPatologies, setSelectedPatologies] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSelectPatology = (patology: string) => {
    setSelectedPatologies((prevSelected) =>
      prevSelected.includes(patology)
        ? prevSelected.filter((item) => item !== patology)
        : [...prevSelected, patology]
    );
  };

  const toggleModal = () => setIsModalVisible((prev) => !prev);

  return (
    <>
      <Header />
      <div className="flex w-full flex-col items-center justify-center pt-16">
        <Section1
          selectedPatologies={selectedPatologies}
          onSelectPatology={handleSelectPatology}
          openModal={toggleModal}
        />
        <Section2 openModal={toggleModal} />
        <Section3 />
        <Section4 />
        <Section5 openModal={toggleModal} />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        {isModalVisible && (
          <Modal
            isVisible={isModalVisible}
            onClose={toggleModal}
            selectedPatologies={selectedPatologies}
            onSelectPatology={handleSelectPatology}
          />
        )}
      </div>
      <Footer />
    </>
  );
};
